<template>
  <div class="reset-password">
    <img src="../assets/images/logo.png" alt="logo" class="logo" />
    <h1>修改密码</h1>
    <Form ref="form">
      <FormItem prop="email">
        <Input type="password" v-model="password" placeholder="新密码">
          <Icon type="ios-lock-outline" slot="prepend"></Icon>
        </Input>
      </FormItem>
      <FormItem prop="passwordConfirmation">
        <Input
          type="password"
          v-model="passwordConfirmation"
          placeholder="重复新密码"
        >
          <Icon type="ios-lock-outline" slot="prepend"></Icon>
        </Input>
      </FormItem>
      <FormItem>
        <div class="button-container">
          <Button
            type="primary"
            size="large"
            @click="changePassword"
            :loading="isLoading"
          >
            修改密码
          </Button>
        </div>
      </FormItem>
    </Form>
    <RouterLink to="/login" class="action-link">登录</RouterLink>
  </div>
</template>

<script>
import { Form, FormItem, Input, Icon, Button, Message } from "view-design";
import { resetPassword } from "../service/auth";
export default {
  name: "ResetPassword",
  components: {
    Form,
    FormItem,
    Input,
    Icon,
    Button,
  },
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      isLoading: false,
    };
  },
  methods: {
    async changePassword() {
      if (this.password !== this.passwordConfirmation) {
        return Message.error("确认密码不相同");
      }
      this.isLoading = true;
      try {
        const data = {
          id: this.$route.query.id,
          password: this.password,
        };
        await resetPassword(data);
        Message.success("密码重置成功");
        this.$router.push("/login");
      } catch (error) {
        Message.error(error.response.data.message);
        console.error(error);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.reset-password {
  max-width: 600px;
  padding: 0 20px;
  margin: 0 auto;
}

.logo {
  width: 80%;
  display: block;
  margin: 100px auto 50px;
}

.button-container {
  text-align: center;
}

.reset-password h1 {
  text-align: center;
  margin-bottom: 20px;
}

.action-link {
  text-align: center;
  display: block;
}
</style>
